import React from 'react';
import { OutboundLink as GtagOutboundLink } from 'gatsby-plugin-google-gtag';
import {
  ChakraProps,
  Link as ChakraLink,
  ThemingProps,
} from '@chakra-ui/react';

interface OutboundLinkProps {
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

export interface LinkProps
  extends Omit<
      React.HTMLProps<HTMLAnchorElement>,
      'height' | 'size' | 'width' | 'color'
    >,
    OutboundLinkProps,
    ThemingProps<'a'>,
    ChakraProps {}

const OutboundLink: React.FC<LinkProps> = props => (
  <ChakraLink
    // @TODO: Fix typing?
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    as={GtagOutboundLink}
    target="_blank"
    rel="noopener noreferrer"
    {...props}
  />
);

export default OutboundLink;
